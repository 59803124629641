import React from 'react';
import './Skills.css';

function Skills() {
    return <div className="skill-content">
        <p>PYTHON * JS * JAVA * C </p>
        <p>HTML * CSS</p>
        <p>PANDAS * DJANGO * REACT</p>
        <p>MONGODB * POSTGRESQL * REDSHIFT</p>
    </div>
}

export default Skills;